<template>
  <v-container class="vh-100" fluid tag="section">
    <v-row class="justify-center vh-100 align-center">
      <div class="d-flex flex-column align-center w-100 mx-1">
        <v-img
          id="logo"
          src="https://switchq.co.il/assets/img/logo.png"
          contain
          max-width="200"
        />
        <v-card id="formcard" class="mx-auto" elevation="12">
          <div id="" class="d-flex flex-column justify-center align-center">
            <v-card-title
              class="text-h2 primary white--text d-flex justify-center"
            >
              <div class="">
                {{ $t('title') }}
              </div>
            </v-card-title>
            <v-card-subtitle class="text-h3 mt-3">
              {{ $t('signin.title') }}
            </v-card-subtitle>

            <div class="w-100 px-8">
              <v-form
                ref="form"
                v-model="valid"
                class="w-100"
                lazy-validation
                @submit.prevent="submit"
              >
                <v-text-field
                  v-if="phase === 1"
                  ref="input1"
                  v-model="form.phone"
                  dir="ltr"
                  :rules="rules.phone"
                  type="tel"
                  :label="$t('form.phone')"
                  class="text-center text-h3"
                  autocomplete="phone"
                  required
                />

                <v-text-field
                  v-if="phase === 2"
                  ref="input2"
                  v-model="form.otp"
                  dir="ltr"
                  :rules="rules.otp"
                  type="tel"
                  :label="$t('form.otp')"
                  class="text-center text-h3"
                  autocomplete="new-password"
                  required
                />

                <v-row v-if="phase === 2" class="pa-3 pb-0">
                  <div class="text-center w-100 grey--text">
                    {{ $t('signin.sentto') }} {{ sent_phone }}
                  </div>
                </v-row>

                <v-row
                  v-if="phase === 2 && (resend_time || timeleft)"
                  class="pa-3 pb-0"
                >
                  <div v-if="resend_time" class="text-center w-100 grey--text">
                    {{
                      `${$t('form.resendable_in')} ${resend_time} ` +
                        $t('time.seconds')
                    }}
                  </div>
                  <div v-else class="text-center w-100 orange--text">
                    {{ codeTimeLeft }}
                  </div>
                </v-row>

                <div v-if="error" class="px-2 mt-5">
                  <v-alert dense type="error" class="pa-1 mb-0">
                    {{ error }}
                  </v-alert>
                </div>
                <div v-if="countdown_error" class="pa-3">
                  <v-alert dense type="warning" class="pa-1 mb-0">
                    {{
                      `${$t('form.resendable_in')} ${resend_time} ` +
                        $t('time.seconds')
                    }}
                  </v-alert>
                </div>
                <div v-if="rate_limit" class="pa-3 mt-2">
                  <v-alert dense type="warning" class="pa-1 mb-0">
                    <h3 class="pa-0 ma-0" v-text="$t('form.too_many')" />
                    {{ rateLimitLeft }}.
                  </v-alert>
                </div>

                <v-card-actions class="d-flex justify-center mt-2">
                  <v-btn
                    ref="submitBtn"
                    color="success"
                    class="text-h3"
                    :loading="loading"
                    type="submit"
                  >
                    {{ $t('form.send') }}
                  </v-btn>
                  <v-spacer v-if="phase === 2" />
                  <v-btn
                    v-if="phase === 2"
                    class="text-h3"
                    color="grey darken-1"
                    outlined
                    :disabled="loading"
                    @click="back"
                  >
                    {{ $t('form.back') }}
                  </v-btn>
                </v-card-actions>
              </v-form>
            </div>
          </div>
        </v-card>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment'
// import Keeper from '@/helpers/keeper'
export default {
  name: 'SignIn',
  data: () => ({
    valid: true,
    loading: null,
    phase: 1,
    created_OTP: null,
    resend_time: null,
    sent_phone: null,
    error: null,
    countdown_error: false,
    countdown_interval: null,
    timeleft: null,
    timeleft_interval: null,
    rate_limit: null,
    rate_limit_interval: null,
    form: {
      phone: null,
      otp: null,
    },
  }),
  computed: {
    rules () {
      return {
        phone: [
          v => !!v || this.$t('form.required'),
          v =>
            !isNaN(v) ||
            `${this.$t('form.input')} ${this.$t('form.phone')} ${this.$t(
              'form.numeric',
            )}`,
          v =>
            (v && v.length === 10) ||
            `${this.$t('form.input')} ${this.$t('form.phone')} ${this.$t(
              'form.mustbe',
            )} ${this.$t('form.exactly')} 10 ${this.$t('form.characters')}`,
        ],
        otp: [
          v => !!v || this.$t('form.required'),
          v =>
            !isNaN(v) ||
            `${this.$t('form.input')} ${this.$t('form.otp')} ${this.$t(
              'form.numeric',
            )}`,
          v =>
            (v && v.length === 6) ||
            `${this.$t('form.input')} ${this.$t('form.otp')} ${this.$t(
              'form.mustbe',
            )} ${this.$t('form.exactly')} 6 ${this.$t('form.characters')}`,
        ],
      }
    },
    codeTimeLeft () {
      let text = this.$t('signin.codevalidtime') + ' '
      if (this.timeleft > 120) {
        text += moment
          .utc(moment.duration(this.timeleft, 'seconds').as('milliseconds'))
          .format('m:ss')
        text += ' ' + this.$t('time.minutes')
      } else {
        text += this.timeleft + ' ' + this.$t('time.seconds')
      }
      return text
    },
    rateLimitLeft () {
      let text = this.$t('form.resendable_in') + ' '
      if (this.rate_limit > 120) {
        const time = moment.utc(
          moment.duration(this.rate_limit, 'seconds').as('milliseconds'),
        )
        text += time.clone().format('m:ss')
        text += ' ' + this.$t('time.minutes')
      } else {
        text += this.rate_limit + ' ' + this.$t('time.seconds')
      }
      return text
    },
  },
  mounted () {
    moment.locale(this.$i18n.locale)
  },
  methods: {
    submit () {
      if (!this.rate_limit && this.$refs.form.validate()) {
        if (this.phase === 1) {
          if (!this.resend_time) {
            this.sendOTP()
          } else if (this.resend_time && this.form.phone !== this.sent_phone) {
            this.resendError()
          } else {
            this.$refs.form.resetValidation()
            this.phase = 2
            this.countdown()
          }
        } else if (this.phase === 2) {
          this.signin()
        }
      }
    },
    sendOTP () {
      this.loading = true
      this.$api
        .post('auth/send-otp', { phone: this.form.phone })
        .then(res => {
          this.loading = false
          this.$refs.form.resetValidation()
          this.phase = 2
          this.sent_phone = this.form.phone
          this.created_OTP = moment(res.data.data)
          this.countdown()
        })
        .catch(err => {
          this.loading = false
          if (err.response.status === 429) {
            this.rateLimit(err.response.headers['retry-after'])
          } else {
            console.log(err.response)
          }
        })
    },
    signin () {
      this.loading = true
      this.$api
        .post('auth/', this.form)
        .then(res => {
          this.loading = false
          this.form = {
            phone: null,
            otp: null,
          }
          this.$store.dispatch('user/authenticated', res.data.data).then(() => {
            this.$router.push('/')
          })
        })
        .catch(err => {
          this.loading = false
          if (err.response.status === 401) {
            this.error = this.$t('signin.invalidcode')
          } else if (err.response.status === 429) {
            this.rateLimit(err.response.headers['retry-after'])
          } else {
            // console.log(err.response)
          }
        })
    },
    countdown () {
      this.resend_time = this.created_OTP
        .clone()
        .add(1, 'm')
        .diff(moment(), 'seconds')
      this.timeleft = this.created_OTP
        .clone()
        .add(5, 'm')
        .diff(moment(), 'seconds')

      this.countdown_interval = setInterval(() => {
        this.resend_time -= 1
        if (this.resend_time === 0) clearInterval(this.countdown_interval)
      }, 1000)

      this.timeleft_interval = setInterval(() => {
        this.timeleft -= 1
        if (this.timeleft === 0) clearInterval(this.timeleft_interval)
      }, 1000)
    },
    rateLimit (ratelimit) {
      this.rate_limit = ratelimit
      this.rate_limit_interval = setInterval(() => {
        this.rate_limit -= 1
        this.$refs.form.resetValidation()
        this.error = null
        if (this.rate_limit === 0) clearInterval(this.rate_limit_interval)
      }, 1000)
    },
    resendError () {
      this.countdown_error = true
    },
    back () {
      this.phase = 1
      this.countdown_error = false
      this.error = null
      clearInterval(this.countdown_interval)
      clearInterval(this.timeleft_interval)
    },
  },
}
</script>

<style lang="sass" scoped>
#formcard
  width: 100%
  max-width: 420px
</style>
